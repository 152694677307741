"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { Signer } from "@ethersproject/abstract-signer";
import { BigNumber } from "@ethersproject/bignumber";
import { hexlify, hexValue, hexZeroPad, isHexString } from "@ethersproject/bytes";
import { _TypedDataEncoder } from "@ethersproject/hash";
import { checkProperties, deepCopy, defineReadOnly, getStatic, resolveProperties, shallowCopy } from "@ethersproject/properties";
import { toUtf8Bytes } from "@ethersproject/strings";
import { accessListify } from "@ethersproject/transactions";
import { fetchJson, poll } from "@ethersproject/web";
import { Logger } from "@ethersproject/logger";
import { version } from "./_version";
const logger = new Logger(version);
import { BaseProvider } from "./base-provider";
const errorGas = ["call", "estimateGas"];
function spelunk(value, requireData) {
  if (value == null) {
    return null;
  }
  // These *are* the droids we're looking for.
  if (typeof value.message === "string" && value.message.match("reverted")) {
    const data = isHexString(value.data) ? value.data : null;
    if (!requireData || data) {
      return {
        message: value.message,
        data
      };
    }
  }
  // Spelunk further...
  if (typeof value === "object") {
    for (const key in value) {
      const result = spelunk(value[key], requireData);
      if (result) {
        return result;
      }
    }
    return null;
  }
  // Might be a JSON string we can further descend...
  if (typeof value === "string") {
    try {
      return spelunk(JSON.parse(value), requireData);
    } catch (error) {}
  }
  return null;
}
function checkError(method, error, params) {
  const transaction = params.transaction || params.signedTransaction;
  // Undo the "convenience" some nodes are attempting to prevent backwards
  // incompatibility; maybe for v6 consider forwarding reverts as errors
  if (method === "call") {
    const result = spelunk(error, true);
    if (result) {
      return result.data;
    }
    // Nothing descriptive..
    logger.throwError("missing revert data in call exception; Transaction reverted without a reason string", Logger.errors.CALL_EXCEPTION, {
      data: "0x",
      transaction,
      error
    });
  }
  if (method === "estimateGas") {
    // Try to find something, with a preference on SERVER_ERROR body
    let result = spelunk(error.body, false);
    if (result == null) {
      result = spelunk(error, false);
    }
    // Found "reverted", this is a CALL_EXCEPTION
    if (result) {
      logger.throwError("cannot estimate gas; transaction may fail or may require manual gas limit", Logger.errors.UNPREDICTABLE_GAS_LIMIT, {
        reason: result.message,
        method,
        transaction,
        error
      });
    }
  }
  // @TODO: Should we spelunk for message too?
  let message = error.message;
  if (error.code === Logger.errors.SERVER_ERROR && error.error && typeof error.error.message === "string") {
    message = error.error.message;
  } else if (typeof error.body === "string") {
    message = error.body;
  } else if (typeof error.responseText === "string") {
    message = error.responseText;
  }
  message = (message || "").toLowerCase();
  // "insufficient funds for gas * price + value + cost(data)"
  if (message.match(/insufficient funds|base fee exceeds gas limit|InsufficientFunds/i)) {
    logger.throwError("insufficient funds for intrinsic transaction cost", Logger.errors.INSUFFICIENT_FUNDS, {
      error,
      method,
      transaction
    });
  }
  // "nonce too low"
  if (message.match(/nonce (is )?too low/i)) {
    logger.throwError("nonce has already been used", Logger.errors.NONCE_EXPIRED, {
      error,
      method,
      transaction
    });
  }
  // "replacement transaction underpriced"
  if (message.match(/replacement transaction underpriced|transaction gas price.*too low/i)) {
    logger.throwError("replacement fee too low", Logger.errors.REPLACEMENT_UNDERPRICED, {
      error,
      method,
      transaction
    });
  }
  // "replacement transaction underpriced"
  if (message.match(/only replay-protected/i)) {
    logger.throwError("legacy pre-eip-155 transactions not supported", Logger.errors.UNSUPPORTED_OPERATION, {
      error,
      method,
      transaction
    });
  }
  if (errorGas.indexOf(method) >= 0 && message.match(/gas required exceeds allowance|always failing transaction|execution reverted|revert/)) {
    logger.throwError("cannot estimate gas; transaction may fail or may require manual gas limit", Logger.errors.UNPREDICTABLE_GAS_LIMIT, {
      error,
      method,
      transaction
    });
  }
  throw error;
}
function timer(timeout) {
  return new Promise(function (resolve) {
    setTimeout(resolve, timeout);
  });
}
function getResult(payload) {
  if (payload.error) {
    // @TODO: not any
    const error = new Error(payload.error.message);
    error.code = payload.error.code;
    error.data = payload.error.data;
    throw error;
  }
  return payload.result;
}
function getLowerCase(value) {
  if (value) {
    return value.toLowerCase();
  }
  return value;
}
const _constructorGuard = {};
export class JsonRpcSigner extends Signer {
  constructor(constructorGuard, provider, addressOrIndex) {
    super();
    if (constructorGuard !== _constructorGuard) {
      throw new Error("do not call the JsonRpcSigner constructor directly; use provider.getSigner");
    }
    defineReadOnly(this, "provider", provider);
    if (addressOrIndex == null) {
      addressOrIndex = 0;
    }
    if (typeof addressOrIndex === "string") {
      defineReadOnly(this, "_address", this.provider.formatter.address(addressOrIndex));
      defineReadOnly(this, "_index", null);
    } else if (typeof addressOrIndex === "number") {
      defineReadOnly(this, "_index", addressOrIndex);
      defineReadOnly(this, "_address", null);
    } else {
      logger.throwArgumentError("invalid address or index", "addressOrIndex", addressOrIndex);
    }
  }
  connect(provider) {
    return logger.throwError("cannot alter JSON-RPC Signer connection", Logger.errors.UNSUPPORTED_OPERATION, {
      operation: "connect"
    });
  }
  connectUnchecked() {
    return new UncheckedJsonRpcSigner(_constructorGuard, this.provider, this._address || this._index);
  }
  getAddress() {
    if (this._address) {
      return Promise.resolve(this._address);
    }
    return this.provider.send("eth_accounts", []).then(accounts => {
      if (accounts.length <= this._index) {
        logger.throwError("unknown account #" + this._index, Logger.errors.UNSUPPORTED_OPERATION, {
          operation: "getAddress"
        });
      }
      return this.provider.formatter.address(accounts[this._index]);
    });
  }
  sendUncheckedTransaction(transaction) {
    transaction = shallowCopy(transaction);
    const fromAddress = this.getAddress().then(address => {
      if (address) {
        address = address.toLowerCase();
      }
      return address;
    });
    // The JSON-RPC for eth_sendTransaction uses 90000 gas; if the user
    // wishes to use this, it is easy to specify explicitly, otherwise
    // we look it up for them.
    if (transaction.gasLimit == null) {
      const estimate = shallowCopy(transaction);
      estimate.from = fromAddress;
      transaction.gasLimit = this.provider.estimateGas(estimate);
    }
    if (transaction.to != null) {
      transaction.to = Promise.resolve(transaction.to).then(to => __awaiter(this, void 0, void 0, function* () {
        if (to == null) {
          return null;
        }
        const address = yield this.provider.resolveName(to);
        if (address == null) {
          logger.throwArgumentError("provided ENS name resolves to null", "tx.to", to);
        }
        return address;
      }));
    }
    return resolveProperties({
      tx: resolveProperties(transaction),
      sender: fromAddress
    }).then(({
      tx,
      sender
    }) => {
      if (tx.from != null) {
        if (tx.from.toLowerCase() !== sender) {
          logger.throwArgumentError("from address mismatch", "transaction", transaction);
        }
      } else {
        tx.from = sender;
      }
      const hexTx = this.provider.constructor.hexlifyTransaction(tx, {
        from: true
      });
      return this.provider.send("eth_sendTransaction", [hexTx]).then(hash => {
        return hash;
      }, error => {
        if (typeof error.message === "string" && error.message.match(/user denied/i)) {
          logger.throwError("user rejected transaction", Logger.errors.ACTION_REJECTED, {
            action: "sendTransaction",
            transaction: tx
          });
        }
        return checkError("sendTransaction", error, hexTx);
      });
    });
  }
  signTransaction(transaction) {
    return logger.throwError("signing transactions is unsupported", Logger.errors.UNSUPPORTED_OPERATION, {
      operation: "signTransaction"
    });
  }
  sendTransaction(transaction) {
    return __awaiter(this, void 0, void 0, function* () {
      // This cannot be mined any earlier than any recent block
      const blockNumber = yield this.provider._getInternalBlockNumber(100 + 2 * this.provider.pollingInterval);
      // Send the transaction
      const hash = yield this.sendUncheckedTransaction(transaction);
      try {
        // Unfortunately, JSON-RPC only provides and opaque transaction hash
        // for a response, and we need the actual transaction, so we poll
        // for it; it should show up very quickly
        return yield poll(() => __awaiter(this, void 0, void 0, function* () {
          const tx = yield this.provider.getTransaction(hash);
          if (tx === null) {
            return undefined;
          }
          return this.provider._wrapTransaction(tx, hash, blockNumber);
        }), {
          oncePoll: this.provider
        });
      } catch (error) {
        error.transactionHash = hash;
        throw error;
      }
    });
  }
  signMessage(message) {
    return __awaiter(this, void 0, void 0, function* () {
      const data = typeof message === "string" ? toUtf8Bytes(message) : message;
      const address = yield this.getAddress();
      try {
        return yield this.provider.send("personal_sign", [hexlify(data), address.toLowerCase()]);
      } catch (error) {
        if (typeof error.message === "string" && error.message.match(/user denied/i)) {
          logger.throwError("user rejected signing", Logger.errors.ACTION_REJECTED, {
            action: "signMessage",
            from: address,
            messageData: message
          });
        }
        throw error;
      }
    });
  }
  _legacySignMessage(message) {
    return __awaiter(this, void 0, void 0, function* () {
      const data = typeof message === "string" ? toUtf8Bytes(message) : message;
      const address = yield this.getAddress();
      try {
        // https://github.com/ethereum/wiki/wiki/JSON-RPC#eth_sign
        return yield this.provider.send("eth_sign", [address.toLowerCase(), hexlify(data)]);
      } catch (error) {
        if (typeof error.message === "string" && error.message.match(/user denied/i)) {
          logger.throwError("user rejected signing", Logger.errors.ACTION_REJECTED, {
            action: "_legacySignMessage",
            from: address,
            messageData: message
          });
        }
        throw error;
      }
    });
  }
  _signTypedData(domain, types, value) {
    return __awaiter(this, void 0, void 0, function* () {
      // Populate any ENS names (in-place)
      const populated = yield _TypedDataEncoder.resolveNames(domain, types, value, name => {
        return this.provider.resolveName(name);
      });
      const address = yield this.getAddress();
      try {
        return yield this.provider.send("eth_signTypedData_v4", [address.toLowerCase(), JSON.stringify(_TypedDataEncoder.getPayload(populated.domain, types, populated.value))]);
      } catch (error) {
        if (typeof error.message === "string" && error.message.match(/user denied/i)) {
          logger.throwError("user rejected signing", Logger.errors.ACTION_REJECTED, {
            action: "_signTypedData",
            from: address,
            messageData: {
              domain: populated.domain,
              types,
              value: populated.value
            }
          });
        }
        throw error;
      }
    });
  }
  unlock(password) {
    return __awaiter(this, void 0, void 0, function* () {
      const provider = this.provider;
      const address = yield this.getAddress();
      return provider.send("personal_unlockAccount", [address.toLowerCase(), password, null]);
    });
  }
}
class UncheckedJsonRpcSigner extends JsonRpcSigner {
  sendTransaction(transaction) {
    return this.sendUncheckedTransaction(transaction).then(hash => {
      return {
        hash: hash,
        nonce: null,
        gasLimit: null,
        gasPrice: null,
        data: null,
        value: null,
        chainId: null,
        confirmations: 0,
        from: null,
        wait: confirmations => {
          return this.provider.waitForTransaction(hash, confirmations);
        }
      };
    });
  }
}
const allowedTransactionKeys = {
  chainId: true,
  data: true,
  gasLimit: true,
  gasPrice: true,
  nonce: true,
  to: true,
  value: true,
  type: true,
  accessList: true,
  maxFeePerGas: true,
  maxPriorityFeePerGas: true
};
export class JsonRpcProvider extends BaseProvider {
  constructor(url, network) {
    let networkOrReady = network;
    // The network is unknown, query the JSON-RPC for it
    if (networkOrReady == null) {
      networkOrReady = new Promise((resolve, reject) => {
        setTimeout(() => {
          this.detectNetwork().then(network => {
            resolve(network);
          }, error => {
            reject(error);
          });
        }, 0);
      });
    }
    super(networkOrReady);
    // Default URL
    if (!url) {
      url = getStatic(this.constructor, "defaultUrl")();
    }
    if (typeof url === "string") {
      defineReadOnly(this, "connection", Object.freeze({
        url: url
      }));
    } else {
      defineReadOnly(this, "connection", Object.freeze(shallowCopy(url)));
    }
    this._nextId = 42;
  }
  get _cache() {
    if (this._eventLoopCache == null) {
      this._eventLoopCache = {};
    }
    return this._eventLoopCache;
  }
  static defaultUrl() {
    return "http:/\/localhost:8545";
  }
  detectNetwork() {
    if (!this._cache["detectNetwork"]) {
      this._cache["detectNetwork"] = this._uncachedDetectNetwork();
      // Clear this cache at the beginning of the next event loop
      setTimeout(() => {
        this._cache["detectNetwork"] = null;
      }, 0);
    }
    return this._cache["detectNetwork"];
  }
  _uncachedDetectNetwork() {
    return __awaiter(this, void 0, void 0, function* () {
      yield timer(0);
      let chainId = null;
      try {
        chainId = yield this.send("eth_chainId", []);
      } catch (error) {
        try {
          chainId = yield this.send("net_version", []);
        } catch (error) {}
      }
      if (chainId != null) {
        const getNetwork = getStatic(this.constructor, "getNetwork");
        try {
          return getNetwork(BigNumber.from(chainId).toNumber());
        } catch (error) {
          return logger.throwError("could not detect network", Logger.errors.NETWORK_ERROR, {
            chainId: chainId,
            event: "invalidNetwork",
            serverError: error
          });
        }
      }
      return logger.throwError("could not detect network", Logger.errors.NETWORK_ERROR, {
        event: "noNetwork"
      });
    });
  }
  getSigner(addressOrIndex) {
    return new JsonRpcSigner(_constructorGuard, this, addressOrIndex);
  }
  getUncheckedSigner(addressOrIndex) {
    return this.getSigner(addressOrIndex).connectUnchecked();
  }
  listAccounts() {
    return this.send("eth_accounts", []).then(accounts => {
      return accounts.map(a => this.formatter.address(a));
    });
  }
  send(method, params) {
    const request = {
      method: method,
      params: params,
      id: this._nextId++,
      jsonrpc: "2.0"
    };
    this.emit("debug", {
      action: "request",
      request: deepCopy(request),
      provider: this
    });
    // We can expand this in the future to any call, but for now these
    // are the biggest wins and do not require any serializing parameters.
    const cache = ["eth_chainId", "eth_blockNumber"].indexOf(method) >= 0;
    if (cache && this._cache[method]) {
      return this._cache[method];
    }
    const result = fetchJson(this.connection, JSON.stringify(request), getResult).then(result => {
      this.emit("debug", {
        action: "response",
        request: request,
        response: result,
        provider: this
      });
      return result;
    }, error => {
      this.emit("debug", {
        action: "response",
        error: error,
        request: request,
        provider: this
      });
      throw error;
    });
    // Cache the fetch, but clear it on the next event loop
    if (cache) {
      this._cache[method] = result;
      setTimeout(() => {
        this._cache[method] = null;
      }, 0);
    }
    return result;
  }
  prepareRequest(method, params) {
    switch (method) {
      case "getBlockNumber":
        return ["eth_blockNumber", []];
      case "getGasPrice":
        return ["eth_gasPrice", []];
      case "getBalance":
        return ["eth_getBalance", [getLowerCase(params.address), params.blockTag]];
      case "getTransactionCount":
        return ["eth_getTransactionCount", [getLowerCase(params.address), params.blockTag]];
      case "getCode":
        return ["eth_getCode", [getLowerCase(params.address), params.blockTag]];
      case "getStorageAt":
        return ["eth_getStorageAt", [getLowerCase(params.address), hexZeroPad(params.position, 32), params.blockTag]];
      case "sendTransaction":
        return ["eth_sendRawTransaction", [params.signedTransaction]];
      case "getBlock":
        if (params.blockTag) {
          return ["eth_getBlockByNumber", [params.blockTag, !!params.includeTransactions]];
        } else if (params.blockHash) {
          return ["eth_getBlockByHash", [params.blockHash, !!params.includeTransactions]];
        }
        return null;
      case "getTransaction":
        return ["eth_getTransactionByHash", [params.transactionHash]];
      case "getTransactionReceipt":
        return ["eth_getTransactionReceipt", [params.transactionHash]];
      case "call":
        {
          const hexlifyTransaction = getStatic(this.constructor, "hexlifyTransaction");
          return ["eth_call", [hexlifyTransaction(params.transaction, {
            from: true
          }), params.blockTag]];
        }
      case "estimateGas":
        {
          const hexlifyTransaction = getStatic(this.constructor, "hexlifyTransaction");
          return ["eth_estimateGas", [hexlifyTransaction(params.transaction, {
            from: true
          })]];
        }
      case "getLogs":
        if (params.filter && params.filter.address != null) {
          params.filter.address = getLowerCase(params.filter.address);
        }
        return ["eth_getLogs", [params.filter]];
      default:
        break;
    }
    return null;
  }
  perform(method, params) {
    return __awaiter(this, void 0, void 0, function* () {
      // Legacy networks do not like the type field being passed along (which
      // is fair), so we delete type if it is 0 and a non-EIP-1559 network
      if (method === "call" || method === "estimateGas") {
        const tx = params.transaction;
        if (tx && tx.type != null && BigNumber.from(tx.type).isZero()) {
          // If there are no EIP-1559 properties, it might be non-EIP-1559
          if (tx.maxFeePerGas == null && tx.maxPriorityFeePerGas == null) {
            const feeData = yield this.getFeeData();
            if (feeData.maxFeePerGas == null && feeData.maxPriorityFeePerGas == null) {
              // Network doesn't know about EIP-1559 (and hence type)
              params = shallowCopy(params);
              params.transaction = shallowCopy(tx);
              delete params.transaction.type;
            }
          }
        }
      }
      const args = this.prepareRequest(method, params);
      if (args == null) {
        logger.throwError(method + " not implemented", Logger.errors.NOT_IMPLEMENTED, {
          operation: method
        });
      }
      try {
        return yield this.send(args[0], args[1]);
      } catch (error) {
        return checkError(method, error, params);
      }
    });
  }
  _startEvent(event) {
    if (event.tag === "pending") {
      this._startPending();
    }
    super._startEvent(event);
  }
  _startPending() {
    if (this._pendingFilter != null) {
      return;
    }
    const self = this;
    const pendingFilter = this.send("eth_newPendingTransactionFilter", []);
    this._pendingFilter = pendingFilter;
    pendingFilter.then(function (filterId) {
      function poll() {
        self.send("eth_getFilterChanges", [filterId]).then(function (hashes) {
          if (self._pendingFilter != pendingFilter) {
            return null;
          }
          let seq = Promise.resolve();
          hashes.forEach(function (hash) {
            // @TODO: This should be garbage collected at some point... How? When?
            self._emitted["t:" + hash.toLowerCase()] = "pending";
            seq = seq.then(function () {
              return self.getTransaction(hash).then(function (tx) {
                self.emit("pending", tx);
                return null;
              });
            });
          });
          return seq.then(function () {
            return timer(1000);
          });
        }).then(function () {
          if (self._pendingFilter != pendingFilter) {
            self.send("eth_uninstallFilter", [filterId]);
            return;
          }
          setTimeout(function () {
            poll();
          }, 0);
          return null;
        }).catch(error => {});
      }
      poll();
      return filterId;
    }).catch(error => {});
  }
  _stopEvent(event) {
    if (event.tag === "pending" && this.listenerCount("pending") === 0) {
      this._pendingFilter = null;
    }
    super._stopEvent(event);
  }
  // Convert an ethers.js transaction into a JSON-RPC transaction
  //  - gasLimit => gas
  //  - All values hexlified
  //  - All numeric values zero-striped
  //  - All addresses are lowercased
  // NOTE: This allows a TransactionRequest, but all values should be resolved
  //       before this is called
  // @TODO: This will likely be removed in future versions and prepareRequest
  //        will be the preferred method for this.
  static hexlifyTransaction(transaction, allowExtra) {
    // Check only allowed properties are given
    const allowed = shallowCopy(allowedTransactionKeys);
    if (allowExtra) {
      for (const key in allowExtra) {
        if (allowExtra[key]) {
          allowed[key] = true;
        }
      }
    }
    checkProperties(transaction, allowed);
    const result = {};
    // JSON-RPC now requires numeric values to be "quantity" values
    ["chainId", "gasLimit", "gasPrice", "type", "maxFeePerGas", "maxPriorityFeePerGas", "nonce", "value"].forEach(function (key) {
      if (transaction[key] == null) {
        return;
      }
      const value = hexValue(BigNumber.from(transaction[key]));
      if (key === "gasLimit") {
        key = "gas";
      }
      result[key] = value;
    });
    ["from", "to", "data"].forEach(function (key) {
      if (transaction[key] == null) {
        return;
      }
      result[key] = hexlify(transaction[key]);
    });
    if (transaction.accessList) {
      result["accessList"] = accessListify(transaction.accessList);
    }
    return result;
  }
}
