"use strict";

import { arrayify } from "@ethersproject/bytes";
export function decode(textData) {
  textData = atob(textData);
  const data = [];
  for (let i = 0; i < textData.length; i++) {
    data.push(textData.charCodeAt(i));
  }
  return arrayify(data);
}
export function encode(data) {
  data = arrayify(data);
  let textData = "";
  for (let i = 0; i < data.length; i++) {
    textData += String.fromCharCode(data[i]);
  }
  return btoa(textData);
}
