"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { arrayify } from "@ethersproject/bytes";
export function getUrl(href, options) {
  return __awaiter(this, void 0, void 0, function* () {
    if (options == null) {
      options = {};
    }
    const request = {
      method: options.method || "GET",
      headers: options.headers || {},
      body: options.body || undefined
    };
    if (options.skipFetchSetup !== true) {
      request.mode = "cors"; // no-cors, cors, *same-origin
      request.cache = "no-cache"; // *default, no-cache, reload, force-cache, only-if-cached
      request.credentials = "same-origin"; // include, *same-origin, omit
      request.redirect = "follow"; // manual, *follow, error
      request.referrer = "client"; // no-referrer, *client
    }
    ;
    if (options.fetchOptions != null) {
      const opts = options.fetchOptions;
      if (opts.mode) {
        request.mode = opts.mode;
      }
      if (opts.cache) {
        request.cache = opts.cache;
      }
      if (opts.credentials) {
        request.credentials = opts.credentials;
      }
      if (opts.redirect) {
        request.redirect = opts.redirect;
      }
      if (opts.referrer) {
        request.referrer = opts.referrer;
      }
    }
    const response = yield fetch(href, request);
    const body = yield response.arrayBuffer();
    const headers = {};
    if (response.headers.forEach) {
      response.headers.forEach((value, key) => {
        headers[key.toLowerCase()] = value;
      });
    } else {
      response.headers.keys().forEach(key => {
        headers[key.toLowerCase()] = response.headers.get(key);
      });
    }
    return {
      headers: headers,
      statusCode: response.status,
      statusMessage: response.statusText,
      body: arrayify(new Uint8Array(body))
    };
  });
}
