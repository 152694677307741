"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { BigNumber } from "@ethersproject/bignumber";
import { isHexString } from "@ethersproject/bytes";
import { Description, defineReadOnly, resolveProperties } from "@ethersproject/properties";
import { Logger } from "@ethersproject/logger";
import { version } from "./_version";
const logger = new Logger(version);
;
;
//export type CallTransactionable = {
//    call(transaction: TransactionRequest): Promise<TransactionResponse>;
//};
export class ForkEvent extends Description {
  static isForkEvent(value) {
    return !!(value && value._isForkEvent);
  }
}
export class BlockForkEvent extends ForkEvent {
  constructor(blockHash, expiry) {
    if (!isHexString(blockHash, 32)) {
      logger.throwArgumentError("invalid blockHash", "blockHash", blockHash);
    }
    super({
      _isForkEvent: true,
      _isBlockForkEvent: true,
      expiry: expiry || 0,
      blockHash: blockHash
    });
  }
}
export class TransactionForkEvent extends ForkEvent {
  constructor(hash, expiry) {
    if (!isHexString(hash, 32)) {
      logger.throwArgumentError("invalid transaction hash", "hash", hash);
    }
    super({
      _isForkEvent: true,
      _isTransactionForkEvent: true,
      expiry: expiry || 0,
      hash: hash
    });
  }
}
export class TransactionOrderForkEvent extends ForkEvent {
  constructor(beforeHash, afterHash, expiry) {
    if (!isHexString(beforeHash, 32)) {
      logger.throwArgumentError("invalid transaction hash", "beforeHash", beforeHash);
    }
    if (!isHexString(afterHash, 32)) {
      logger.throwArgumentError("invalid transaction hash", "afterHash", afterHash);
    }
    super({
      _isForkEvent: true,
      _isTransactionOrderForkEvent: true,
      expiry: expiry || 0,
      beforeHash: beforeHash,
      afterHash: afterHash
    });
  }
}
///////////////////////////////
// Exported Abstracts
export class Provider {
  constructor() {
    logger.checkAbstract(new.target, Provider);
    defineReadOnly(this, "_isProvider", true);
  }
  getFeeData() {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        block,
        gasPrice
      } = yield resolveProperties({
        block: this.getBlock("latest"),
        gasPrice: this.getGasPrice().catch(error => {
          // @TODO: Why is this now failing on Calaveras?
          //console.log(error);
          return null;
        })
      });
      let lastBaseFeePerGas = null,
        maxFeePerGas = null,
        maxPriorityFeePerGas = null;
      if (block && block.baseFeePerGas) {
        // We may want to compute this more accurately in the future,
        // using the formula "check if the base fee is correct".
        // See: https://eips.ethereum.org/EIPS/eip-1559
        lastBaseFeePerGas = block.baseFeePerGas;
        maxPriorityFeePerGas = BigNumber.from("1500000000");
        maxFeePerGas = block.baseFeePerGas.mul(2).add(maxPriorityFeePerGas);
      }
      return {
        lastBaseFeePerGas,
        maxFeePerGas,
        maxPriorityFeePerGas,
        gasPrice
      };
    });
  }
  // Alias for "on"
  addListener(eventName, listener) {
    return this.on(eventName, listener);
  }
  // Alias for "off"
  removeListener(eventName, listener) {
    return this.off(eventName, listener);
  }
  static isProvider(value) {
    return !!(value && value._isProvider);
  }
}
