/* istanbul ignore file */
"use strict";

import { UrlJsonRpcProvider } from "./url-json-rpc-provider";
import { Logger } from "@ethersproject/logger";
import { version } from "./_version";
const logger = new Logger(version);
// Special API key provided by Nodesmith for ethers.js
const defaultApiKey = "ETHERS_JS_SHARED";
export class NodesmithProvider extends UrlJsonRpcProvider {
  static getApiKey(apiKey) {
    if (apiKey && typeof apiKey !== "string") {
      logger.throwArgumentError("invalid apiKey", "apiKey", apiKey);
    }
    return apiKey || defaultApiKey;
  }
  static getUrl(network, apiKey) {
    logger.warn("NodeSmith will be discontinued on 2019-12-20; please migrate to another platform.");
    let host = null;
    switch (network.name) {
      case "homestead":
        host = "https://ethereum.api.nodesmith.io/v1/mainnet/jsonrpc";
        break;
      case "ropsten":
        host = "https://ethereum.api.nodesmith.io/v1/ropsten/jsonrpc";
        break;
      case "rinkeby":
        host = "https://ethereum.api.nodesmith.io/v1/rinkeby/jsonrpc";
        break;
      case "goerli":
        host = "https://ethereum.api.nodesmith.io/v1/goerli/jsonrpc";
        break;
      case "kovan":
        host = "https://ethereum.api.nodesmith.io/v1/kovan/jsonrpc";
        break;
      default:
        logger.throwArgumentError("unsupported network", "network", arguments[0]);
    }
    return host + "?apiKey=" + apiKey;
  }
}
