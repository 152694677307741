"use strict";

import { arrayify, hexlify } from "@ethersproject/bytes";
import { toUtf8Bytes, UnicodeNormalizationForm } from '@ethersproject/strings';
export function looseArrayify(hexString) {
  if (typeof hexString === 'string' && hexString.substring(0, 2) !== '0x') {
    hexString = '0x' + hexString;
  }
  return arrayify(hexString);
}
export function zpad(value, length) {
  value = String(value);
  while (value.length < length) {
    value = '0' + value;
  }
  return value;
}
export function getPassword(password) {
  if (typeof password === 'string') {
    return toUtf8Bytes(password, UnicodeNormalizationForm.NFKC);
  }
  return arrayify(password);
}
export function searchPath(object, path) {
  let currentChild = object;
  const comps = path.toLowerCase().split('/');
  for (let i = 0; i < comps.length; i++) {
    // Search for a child object with a case-insensitive matching key
    let matchingChild = null;
    for (const key in currentChild) {
      if (key.toLowerCase() === comps[i]) {
        matchingChild = currentChild[key];
        break;
      }
    }
    // Didn't find one. :'(
    if (matchingChild === null) {
      return null;
    }
    // Now check this child...
    currentChild = matchingChild;
  }
  return currentChild;
}
// See: https://www.ietf.org/rfc/rfc4122.txt (Section 4.4)
export function uuidV4(randomBytes) {
  const bytes = arrayify(randomBytes);
  // Section: 4.1.3:
  // - time_hi_and_version[12:16] = 0b0100
  bytes[6] = bytes[6] & 0x0f | 0x40;
  // Section 4.4
  // - clock_seq_hi_and_reserved[6] = 0b0
  // - clock_seq_hi_and_reserved[7] = 0b1
  bytes[8] = bytes[8] & 0x3f | 0x80;
  const value = hexlify(bytes);
  return [value.substring(2, 10), value.substring(10, 14), value.substring(14, 18), value.substring(18, 22), value.substring(22, 34)].join("-");
}
