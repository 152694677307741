import { Blockchain, Chain, ChainId } from './types';

export function shortAddress(value: string, ...args: unknown[])
{
    if (value == null)
    {
        return value;
    }

    const size = value.length;
    const end: number = Number(args[ 0 ]) || 4;
    const start: number = Number(args[ 1 ]) || 6;

    const SUBSTITUTE = "..";

    // SHORT_PART can be bigger than actual text by one symbol.
    const REQUIRED_MIN_SIZE = end + start + SUBSTITUTE.length;

    if (size < REQUIRED_MIN_SIZE)
    {
        return value;
    }

    return (value.slice(0, start) + SUBSTITUTE + value.slice(size - end, size));
}

export const CHAINS: Map<ChainId, Chain> = new Map<number, Chain>
    ([
        [ ChainId.ETHEREUM_MAINNET, { chainId: ChainId.ETHEREUM_MAINNET, blockchain: Blockchain.ETHEREUM, isMainnet: true, displayName: "Ethereum Mainnet", shortName: 'Ethereum', icon: "/assets/images/ethereum.svg" } ],
        [ ChainId.ETHEREUM_KOVAN, { chainId: ChainId.ETHEREUM_KOVAN, blockchain: Blockchain.ETHEREUM, isMainnet: false, displayName: "Kovan Test Network", shortName: 'Ethereum', icon: "/assets/images/ethereum.svg" } ],
        [ ChainId.ETHEREUM_GOERLI, { chainId: ChainId.ETHEREUM_GOERLI, blockchain: Blockchain.ETHEREUM, isMainnet: false, displayName: "Goerli Test Network", shortName: 'Ethereum', icon: "/assets/images/ethereum.svg" } ],
        [ ChainId.ETHEREUM_SEPOLIA, { chainId: ChainId.ETHEREUM_SEPOLIA, blockchain: Blockchain.ETHEREUM, isMainnet: false, displayName: "Sepolia Test Network", shortName: 'Ethereum', icon: "/assets/images/ethereum.svg" } ],
        [ ChainId.ETHEREUM_ROPSTEN, { chainId: ChainId.ETHEREUM_ROPSTEN, blockchain: Blockchain.ETHEREUM, isMainnet: false, displayName: "Ropsten Test Network", shortName: 'Ethereum', icon: "/assets/images/ethereum.svg" } ],
        [ ChainId.HARDHAT_TESTNET, { chainId: ChainId.HARDHAT_TESTNET, blockchain: Blockchain.ETHEREUM, isMainnet: false, displayName: "Hardhat Test Network", shortName: 'HH', icon: "/assets/images/ethereum.svg" } ],
        [ ChainId.BSC_MAINNET, { chainId: ChainId.BSC_MAINNET, blockchain: Blockchain.BINANCE_SMART_CHAIN, isMainnet: true, displayName: "Binance Smart Chain", shortName: 'BSC', icon: "/assets/images/bsc.svg" } ],
        [ ChainId.BSC_TESTNET, { chainId: ChainId.BSC_TESTNET, blockchain: Blockchain.BINANCE_SMART_CHAIN, isMainnet: false, displayName: "Binance Smart Chain - Testnet", shortName: 'BSC', icon: "/assets/images/bsc.svg" } ],
        [ ChainId.POLYGON_MAINNET, { chainId: ChainId.POLYGON_MAINNET, blockchain: Blockchain.POLYGON, isMainnet: true, displayName: "Polygon", shortName: 'Polygon', icon: "/assets/images/polygon.svg" } ],
        [ ChainId.POLYGON_AMOY, { chainId: ChainId.POLYGON_AMOY, blockchain: Blockchain.POLYGON, isMainnet: false, displayName: "Polygon - Testnet", shortName: 'Polygon', icon: "/assets/images/polygon.svg" } ],
        [ ChainId.FANTOM_MAINNET, { chainId: ChainId.FANTOM_MAINNET, blockchain: Blockchain.FANTOM, isMainnet: true, displayName: "Fantom Opera", shortName: 'Fantom', icon: "/assets/images/fantom.svg" } ],
        [ ChainId.FANTOM_TESTNET, { chainId: ChainId.FANTOM_TESTNET, blockchain: Blockchain.FANTOM, isMainnet: false, displayName: "Fantom Testnet", shortName: 'Fantom', icon: "/assets/images/fantom.svg" } ],
        [ ChainId.AVALANCHE_MAINNET, { chainId: ChainId.AVALANCHE_MAINNET, blockchain: Blockchain.AVALANCHE, isMainnet: true, displayName: "Avalanche C-Chain", shortName: 'Avalanche', icon: "/assets/images/avalanche.svg" } ],
        [ ChainId.AVALANCHE_TESTNET, { chainId: ChainId.AVALANCHE_TESTNET, blockchain: Blockchain.AVALANCHE, isMainnet: false, displayName: "Avalanche Fuji Testnet", shortName: 'Avalanche', icon: "/assets/images/avalanche.svg" } ],
        [ ChainId.ARBITRUM_MAINNET, { chainId: ChainId.ARBITRUM_MAINNET, blockchain: Blockchain.ARBITRUM, isMainnet: true, displayName: "Arbitum One", shortName: 'Arbitum', icon: "/assets/images/arbitrum.svg" } ],
        [ ChainId.BASE_MAINNET, { chainId: ChainId.BASE_MAINNET, blockchain: Blockchain.BASE, isMainnet: true, displayName: "Base", shortName: 'Base', icon: "/assets/images/base.svg" } ],
        [ ChainId.TON_MAINNET, { chainId: ChainId.TON_MAINNET, blockchain: Blockchain.TON, isMainnet: true, displayName: "The Open Network", shortName: 'TON', icon: "/assets/images/ton.svg" } ],
        [ ChainId.TON_TESTNET, { chainId: ChainId.TON_TESTNET, blockchain: Blockchain.TON, isMainnet: false, displayName: "The Open Network - Testnet", shortName: 'TON', icon: "/assets/images/ton.svg" } ],
    ]);

export const ETHERSCAN_URI = {
    [ ChainId.ETHEREUM_MAINNET ]: 'https://api.etherscan.io/api',
    [ ChainId.ETHEREUM_ROPSTEN ]: 'https://api-ropsten.etherscan.io/api',
    [ ChainId.ETHEREUM_GOERLI ]: 'https://api-goerli.etherscan.io/api',
    [ ChainId.ETHEREUM_SEPOLIA ]: 'https://api-sepolia.etherscan.io/api',
    [ ChainId.ETHEREUM_KOVAN ]: 'https://api-kovan.etherscan.io/api',
    [ ChainId.BSC_MAINNET ]: 'https://api.bscscan.com/api',
    [ ChainId.BSC_TESTNET ]: 'https://api-testnet.bscscan.com/api',
    [ ChainId.AVALANCHE_MAINNET ]: 'https://api.snowtrace.io/api',
    [ ChainId.AVALANCHE_TESTNET ]: 'https://api-testnet.snowtrace.io/api',
    [ ChainId.BASE_MAINNET ]: 'https://api.basescan.org/api',
};

export const metamaskNetworks = [
    {
        chainId: `0x${ Number(56).toString(16) }`,
        chainName: 'Binance Smart Chain Mainnet',
        rpcUrls: [ 'https://bsc-dataseed1.binance.org/' ],
        nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18
        },
        blockExplorerUrls: [ 'https://bscscan.com/' ]
    },
    {
        chainId: `0x${ Number(97).toString(16) }`,
        chainName: 'Binance Smart Chain Testnet',
        rpcUrls: [ 'https://data-seed-prebsc-1-s1.binance.org:8545/' ],
        nativeCurrency: {
            name: "tBNB",
            symbol: "tBNB",
            decimals: 18
        },
        blockExplorerUrls: [ 'https://testnet.bscscan.com/' ]
    },
    {
        chainId: `0x${ Number(137).toString(16) }`,
        chainName: 'Polygon Mainnet',
        rpcUrls: [ 'https://rpc-mainnet.maticvigil.com/' ],
        nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18
        },
        blockExplorerUrls: [ 'https://polygonscan.com/' ]
    },
    {
        chainId: `0x${ Number(80002).toString(16) }`,
        chainName: 'Matic Amoy',
        rpcUrls: [ 'https://rpc-amoy.polygon.technology' ],
        nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18
        },
        blockExplorerUrls: [ 'https://amoy.polygonscan.com' ]
    },
    {
        chainId: `0x${ Number(250).toString(16) }`,
        chainName: 'Fantom Opera',
        rpcUrls: [ 'https://rpc.ftm.tools/' ],
        nativeCurrency: {
            name: "FTM",
            symbol: "FTM",
            decimals: 18
        },
        blockExplorerUrls: [ 'https://ftmscan.com/' ]
    },
    {
        chainId: `0x${ Number(4002).toString(16) }`,
        chainName: 'Fantom testnet',
        rpcUrls: [ 'https://rpc.testnet.fantom.network/' ],
        nativeCurrency: {
            name: "FTM",
            symbol: "FTM",
            decimals: 18
        },
        blockExplorerUrls: [ 'https://testnet.ftmscan.com/' ]
    },
    {
        chainId: `0x${ Number(43114).toString(16) }`,
        chainName: 'Avalanche C-Chain',
        rpcUrls: [ 'https://api.avax.network/ext/bc/C/rpc' ],
        nativeCurrency: {
            name: "AVAX",
            symbol: "AVAX",
            decimals: 18
        },
        blockExplorerUrls: [ 'https://snowtrace.io/' ]
    },
    {
        chainId: `0x${ Number(43113).toString(16) }`,
        chainName: 'Avalanche Fuji Testnet',
        rpcUrls: [ 'https://api.avax-test.network/ext/bc/C/rpc' ],
        nativeCurrency: {
            name: "AVAX",
            symbol: "AVAX",
            decimals: 18
        },
        blockExplorerUrls: [ 'https://testnet.snowtrace.io/' ]
    },
    {
        chainId: `0x${ Number(8453).toString(16) }`,
        chainName: 'Base Mainnet',
        rpcUrls: [ 'https://mainnet.base.org' ],
        nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18
        },
        blockExplorerUrls: [ 'https://basescan.org/' ]
    },
];

export function getBlockchainByChainId(_chainId: ChainId): Blockchain
{
    const chain: Chain = <Chain>CHAINS.get(_chainId);
    return chain.blockchain;
}

export function dump(_chainId: ChainId): string
{
    return `value/${ _chainId }, enumItem/${ ChainId[ _chainId ] }`;
}
export function isChainIdEthereum(_chainId: ChainId): boolean
{
    return _chainId == ChainId.ETHEREUM_MAINNET
        || _chainId == ChainId.ETHEREUM_GOERLI
        || _chainId == ChainId.ETHEREUM_ROPSTEN
        || _chainId == ChainId.ETHEREUM_KOVAN
        || _chainId == ChainId.ETHEREUM_SEPOLIA
        || _chainId == ChainId.BASE_MAINNET;
}
export function isChainIdBsc(_chainId: ChainId): boolean
{
    return _chainId == ChainId.BSC_MAINNET
        || _chainId == ChainId.BSC_TESTNET;
}

export function isChainIdPolygon(_chainId: ChainId): boolean
{
    return _chainId == ChainId.POLYGON_AMOY || _chainId == ChainId.POLYGON_MAINNET;
}

export function isChainIdFantom(_chainId: ChainId): boolean
{
    return _chainId == ChainId.FANTOM_MAINNET
        || _chainId == ChainId.FANTOM_TESTNET;
}

export function isChainIdAvalanche(_chainId: ChainId): boolean
{
    return _chainId == ChainId.AVALANCHE_MAINNET
        || _chainId == ChainId.AVALANCHE_TESTNET;
}

export function isChainIdArbitrum(_chainId: ChainId): boolean
{
    return _chainId == ChainId.ARBITRUM_MAINNET;
}

export async function sleep(ms: number)
{
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isChainIdMainnet(_chainId: ChainId): boolean
{
    const chain: Chain = <Chain>CHAINS.get(_chainId);
    return chain !== undefined && chain.isMainnet;
}

export function isChainIdTon(_chainId: ChainId): boolean
{
    return _chainId == ChainId.TON_MAINNET || _chainId == ChainId.TON_TESTNET;
}

//**************************************************************************************************

export function getBlockchainBaseCurrency(_chainId: ChainId): any
{
    if (isChainIdEthereum(_chainId)) return 'ETH';

    if (isChainIdBsc(_chainId)) return 'BNB';

    if (isChainIdPolygon(_chainId)) return 'MATIC';

    if (isChainIdFantom(_chainId)) return 'FTM';

    if (isChainIdAvalanche(_chainId)) return 'AVAX';

    if (isChainIdArbitrum(_chainId)) return 'ETH';

    if (isChainIdTon(_chainId)) return 'TON';
}
